<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <div class="separator">
        <p class="breadcrumb-menu">
          <router-link :to="{ name: 'ScanLogs', query: this.createSearchQuery() }" class="ashen-link">入場ログ参照
          </router-link>
          <i class="fas fa-angle-right form-control-color"></i> 入場ログ詳細
        </p>
      </div>
      <div class="card border-0 text-first mt-3">
        <div class="row d-flex justify-content-between">
          <div v-if="prevScanLogString" class="col-6">
            <router-link :to="linkPrevScanLog" class="ashen-link mx-1" replace>
              <i class="fas fa-angle-left"></i>
              {{ prevScanLogString }}
            </router-link>
          </div>
          <div v-else class="col-6"></div>
          <div v-if="nextScanLogString" class="col-6 text-end">
            <router-link :to="linkNextScanLog" class="ashen-link mx-1" replace>
              {{ nextScanLogString }}<i class="fas fa-angle-right mx-1"></i>
            </router-link>
          </div>
          <div v-else class="col-6"></div>
        </div>
      </div>
    </div>
    <div class="card text-first mt-3">
      <div class="card-header bg-transparent">
        <img src="../assets/images/racket-gray.png"/>
        <span class="mx-3">{{ tournamentName }}</span>
      </div>
      <div class="card-body mt-4 mb-4 px-5">
        <div class="row">
          <div class="col">
            <label for="exampleInputPassword1">入場日時</label>
            <input :value="getDateTimeFormat(scanLog.dateTime)" class="form-control half-width-input mt-1" disabled
                   type="text">
            <div v-show="errors['dateTime']" class="form-error">{{ errors['dateTime'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">入場者</label>
            <input v-model="scanLog.lastName" class="form-control half-width-input" disabled type="text">
            <div v-show="errors['lastName']" class="form-error">{{ errors['lastName'] }}</div>
          </div>
          <div class="col">
            <label for="exampleInputPassword1"></label>
            <input v-model="scanLog.firstName" class="form-control half-width-input" disabled type="text">
            <div v-show="errors['firstName']" class="form-error">{{ errors['firstName'] }}</div>
          </div>
          <div class="col">
            <label for="exampleInputPassword1"></label>
            <input v-model="scanLog.userId" class="form-control half-width-input" disabled type="text">
            <div v-show="errors['userId']" class="form-error">{{ errors['userId'] }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "../assets/jsadminclient/common";
import Helper from "../assets/jsadminclient/helper";
import {mapGetters} from "vuex";

export default {
  name: "ScanLogDetail",
  props: {
    tournamentId: {type: Number, default: null},
    scanLogId: {type: Number, default: null}
  },
  data() {
    return {
      loading: true,
      tournamentList: {},
      tournamentName: '',
      nextUserId: '',
      nextUserName: '',
      prevUserId: '',
      prevUserName: '',
      nextScanLogId: '',
      prevScanLogId: '',
      scanLog: {},
      reloadKey: 1,
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
    prevScanLogString() {
      return this.prevUserName ? this.prevUserName + '(' + this.prevUserId + ')' : ''
    },
    nextScanLogString() {
      return this.nextUserName ? this.nextUserName + '(' + this.nextUserId + ')' : ''
    },
    linkPrevScanLog() {
      return {
        name: "ScanLogDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          scanLogId: this.prevScanLogId,
        },
        query: this.createSearchQuery()
      }
    },
    linkNextScanLog() {
      return {
        name: "ScanLogDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          scanLogId: this.nextScanLogId,
        },
        query: this.createSearchQuery()
      }
    }
  },
  watch: {
    $route(to, from) {
      // Called from back or next
      this.getScanLog()
    }
  },
  mounted() {
    this.initialPageLoadHandler()
  },
  methods: {
    initialPageLoadHandler() {
      this.getScanLog()
      this.getTournament()
    },
    getDateTimeFormat(dtm) {
      return moment(dtm).format('YYYY/MM/DD  HH:mm')
    },
    async getScanLog() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.scanLog = await api.tournamentsTournamentIdScanLogsScanLogIdGetAsync(
            this.tournamentId,
            this.scanLogId,
            this.$route.query.startAt,
            this.$route.query.endAt,
            this.$route.query.startTime,
            this.$route.query.endTime,
            this.$route.query.keyword,
            this.$route.query.sort,
            this.$route.query.order
        );
        this.scanLogId = this.scanLog.scanLogId
        this.nextUserId = this.scanLog.nextUserId
        this.nextUserName = this.scanLog.nextUserName
        this.prevUserId = this.scanLog.prevUserId
        this.prevUserName = this.scanLog.prevUserName
        this.prevScanLogId = this.scanLog.prevScanLogId
        this.nextScanLogId = this.scanLog.nextScanLogId

      } catch (e) {
        this.scanLog = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },

    async getTournament() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsListTournamentIdGetAsync(
            this.tournamentId,
        );
        this.tournamentName = result.tournamentName
      } catch (e) {
        this.tournamentName = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

.delete {
  width: 160px;
  height: 44px;
}

.form-check {
  padding-left: 33px;
}


.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

::placeholder {
  color: #888888;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.filter-select,
.filter-search-form,
.btn-search {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  border: 1px solid #dee2e6;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.content {
  &.touradminsip {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }
}
</style>